<template>
  <div class="px-5" id="page-shortlink">
    <p>Redirecting shortlink... {{ linkstr }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'shortlink',
  components: {
    
  },
  computed: {
    ...mapGetters(['isMemAuthenticated', 'currentMember']),
  },
  async mounted() {
    this.linkstr = this.$route.params.linkstr;

    this.routeShortLink();
  },
  data() {
    return {
      linkstr: null
    };
  },
  watch: {

  },
  methods: {
    routeShortLink() {
      axios 
        .get(`/link/linkstr/${this.linkstr}`)
        .then(res => {
          if (res.status === 200) {
            const link = res.data;

            this.$router.push(link.href);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>
